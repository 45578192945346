import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "@suziwen/gitalk/dist/gitalk.css"
import Gitalk from "gatsby-plugin-gitalk"
// import MyGitalk from "../components/gitalk"
// import GitalkComponent from "gitalk/dist/gitalk-component"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  let gitalkConfig = {
    id: post.id || post.slug,
    title: post.frontmatter.title,
  }

  // let id = post.slug
  // let title = post.title
  console.log(post.frontmatter.title)
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          <p>{post.frontmatter.updated}</p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      {/* <GitalkComponent
        options={{
          clientID: "1e8be0a3a46185d3710f",
          clientSecret: "4a9bddcbf13016b7b407cac2419d2ef375d6e788",
          repo: "nozzlex3.github.io",
          owner: "nozzlex3",
          admin: ["nozzlex3"],
          pagerDirection: "last",
          createIssueManually: true,
          distractionFreeMode: true,
          enableHotKey: true,
          id: post.slug,
          title: post.frontmatter.title,
        }}
      /> */}
      {/* <Gitalk title={post.frontmatter.title} id={post.id} /> */}
      <Gitalk options={gitalkConfig} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "Y-M-D ddd")
        updated(formatString: "Y-M-D ddd")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
